import { inject } from "aurelia-framework";
import { default as recommendationService } from "services/recommendationService";
import { default as userSettingService }  from "services/userSettingsService";

@inject(userSettingService)
export class Recommendation {
    fetchRecommendations;
    displayAllRecommendations = false;

    constructor(userSettingService) {
        this.userSettingService = userSettingService;
    }

    async activate() {
        const usersetting = await this.userSettingService.getRecommendationForCurrentUserSetting();
        if (usersetting) {
            this.displayAllRecommendations = usersetting.Value === "true";
        }

        this.fetchRecommendations = async (completedOnly, enteredOnly, inProgressOnly, filter, page) => await recommendationService.getRecommendations(completedOnly, enteredOnly, inProgressOnly, filter, page).promise();
    }
}
