import { default as recommendationService } from "services/recommendationService";

import { default as queryStringHelper } from "helpers/queryStringHelper";
import { default as routerHelper } from "helpers/routerHelper";
import userSettingService from "services/userSettingsService";

export class Recommendation {
    dispatchId = "";
    entityId;
    serviceType = "";
    fetchRecommendations;
    readonly = false;
    fromHistory = false;
    maintenanceEquipmentCode = null;
    maintenanceEquipmentDescription = null;
    displayAllRecommendation = false;
    parentModel;

    constructor() {
    }

    async activate(params) {
        await this.bindViewModel(params.serviceType, params.dispatchId, params.q);
    }

    async bindViewModel(serviceType, dispatchId, querystring) {
        const params = routerHelper.getQuerystring(querystring);
        if (params.readonly) {
            this.readonly = queryStringHelper.parseReadonly(querystring);
        }

        if (params.maintenanceEquipmentCode) {
            this.maintenanceEquipmentCode = params.maintenanceEquipmentCode;

            const usersetting = await userSettingService.getRecommendationForMaintenanceCurrentUserSetting();
            if (usersetting) {
                this.displayAllRecommendation = usersetting.Value === "true";
            }
        }

        if (params.maintenanceEquipmentDescription) {
            this.maintenanceEquipmentDescription = params.maintenanceEquipmentDescription;
        }

        this.fromHistory = queryStringHelper.parse(querystring, "fromHistory") === "true";
       
        this.entityId = params.entityId;
        this.serviceType = serviceType;
        this.dispatchId = dispatchId;
        this.fetchRecommendations = async (status, filter, page) => await recommendationService.getRecommendationsForDispatch(dispatchId, status, filter, page, this.fromHistory, this.displayAllRecommendation).promise();
        this.parentModel = this;
    }
}
