define([
    "services/requests"
], function (requests) {
    "use strict";

    var exports = {
        getMobileUserSettings: function (username) {
            return requests.getJson(`api/mobile/${username}/settings`);
        },

        setMobileUserSettings: function (userSettings) {
            return requests.post(`api/mobile/settings`, userSettings);
        },

        getOnlyActiveDispatchSetting: function () {
            return requests.getJson(`api/settings/current-user/only-active-dispatch`);
        },

        getUserAccessType: function (username) {
            return requests.getJson(`api/mobile/${username}/access-type`);
        },

        getUserLanguage: function (username) {
            return requests.getJson(`api/mobile/${username}/language`);
        },

        getPrefix: function(companyId) {
            return requests.getJson(`api/mobile/${companyId}/prefix`);
        },

        setOnlyActiveDispatchSetting: function (value) {
            return requests.post(`api/settings/current-user/only-active-dispatch?value=` + value);
        },

        getOnlyActiveServiceCallContractEquipmentSetting: function () {
            return requests.getJson(`api/settings/current-user/only-active-service-call-contract-equipment`);
        },

        setOnlyActiveServiceCallContractEquipmentSetting: function (value) {
            return requests.post(`api/settings/current-user/only-active-service-call-contract-equipment?settingValue=` + value);
        },

        getReceptionImplicitSetting: function () {
            return requests.getJson(`api/settings/current-user/implicit-reception`);
        },

        setReceptionImplicitSetting: function (value) {
            return requests.post(`api/settings/current-user/implicit-reception?settingValue=` + value);
        },

        getUnPlannedServiceCallWithinRangeSetting: function (unitSystem) {
            return requests.getJson(`api/settings/current-user/unplanned-service-call-within-range-setting?unitSystem=` + unitSystem);
        },

        setUnPlannedServiceCallWithinRangeSetting: function (value, unitSystem) {
            return requests.post(`api/settings/current-user/unplanned-service-call-within-range-setting?settingValue=` + value + `&unitSystem=` + unitSystem);
        },

        getPurchaseOrdersForCurrentUserSetting: function () {
            return requests.getJson(`api/settings/current-user/purchase-orders/current-user-only`);
        },

        setPurchaseOrdersForCurrentUserSetting: function (value) {
            return requests.post(`api/settings/current-user/purchase-orders/current-user-only?value=` + value);
        },

        getRequisitionsForCurrentUserSetting: function () {
            return requests.getJson(`api/settings/current-user/requisitions/current-user-only`);
        },

        setRequisitionsForCurrentUserSetting: function (value) {
            return requests.post(`api/settings/current-user/requisitions/current-user-only?value=` + value);
        },

        getEquipmentRequisitionsForCurrentUserSetting: function () {
            return requests.getJson(`api/settings/current-user/equipment-requisitions/current-user-only`);
        },

        setEquipmentRequisitionsForCurrentUserSetting: function (value) {
            return requests.post(`api/settings/current-user/equipment-requisitions/current-user-only?value=` + value);
        },

        getPhInvIncludeReservationNoForCurrentUserSetting: function () {
            return requests.getJson(`api/settings/current-user/physical-inventory/Include-items-with-reservation-no`);
        },

        setPhInvIncludeReservationNoForCurrentUserSetting: function (value) {
            return requests.post(`api/settings/current-user/physical-inventory/Include-items-with-reservation-no?value=` + value);
        },

        getProfileEmployeeDocumentByCategoryDrawerOpenForCurrentUserSetting: function () {
            return requests.getJson(`api/settings/current-user/profile-employee/document-by-category-drawer-open`);
        },

        setProfileEmployeeDocumentByCategoryDrawerOpenForCurrentUserSetting: function (value) {
            return requests.post(`api/settings/current-user/profile-employee/document-by-category-drawer-open?value=` + value);
        },

        getRecommendationForCurrentUserSetting: function () {
            return requests.getJson(`api/settings/current-user/recommendation/current-user-only`);
        },

        setRecommendationForCurrentUserSetting: function (value) {
            return requests.post(`api/settings/current-user/recommendation/current-user-only?value=` + value);
        },

        getRecommendationForMaintenanceCurrentUserSetting: function () {
            return requests.getJson(`api/settings/current-user/recommendation/maintenance/current-user-only`);
        },

        setRecommendationForMaintenanceCurrentUserSetting: function (value) {
            return requests.post(`api/settings/current-user/recommendation/maintenance/current-user-only?value=` + value);
        }
    };
    return exports;
});