define([
    "services/requests",
    "helpers/apiHelper"
], function (requests, apiHelper) {
    "use strict";

    function getRecommendationsFromUrl(url, status, filter, page, fromHistory, allRecommendations) {
        url = url + "/recommendations?"
            + "recommendationstatus=" + status
            + (filter ? "&filter=" + filter : "")
            + (page ? "&page=" + page : "");

        if (typeof fromHistory !== 'undefined' && fromHistory !== null) {
            url = url + "&fromHistory=" + fromHistory;
        }

        if (typeof allRecommendations !== 'undefined' && allRecommendations !== null) {
            url = url + "&allRecommendations=" + allRecommendations;
        }

        return requests.getJson(url);
    }

    var exports = {
        getRecommendationTypes: function () {
            return requests.getJson(apiHelper.getBaseUrl() + "/recommendationtypes");
        },

        addRecommendation: function (data) {
            return requests.post(apiHelper.getBaseUrl() + "/recommendations", data);
        },

        addRecommendationForDispatch: function (dispatchId, data) {
            return requests.post(apiHelper.getDispatchBaseUrl(dispatchId) + "/recommendations", data);
        },

        addRecommendationForProject: function (dispatchProjectCode, data) {
            return requests.post(apiHelper.getDispatchProjectBaseUrl(dispatchProjectCode) + "/recommendations", data);
        },

        addRecommendationForWorkOrder: function (workOrderId, data) {
            return requests.post(apiHelper.getWorkOrderBaseUrl(workOrderId) + "/recommendations", data);
        },

        getRecommendations: function (status, filter, page, allRecommendations) {
            return getRecommendationsFromUrl(apiHelper.getBaseUrl(), status, filter, page, null, allRecommendations);
        },

        getRecommendationsForDispatch: function (dispatchId, status, filter, page, fromHistory, allRecommendations) {
            return getRecommendationsFromUrl(apiHelper.getDispatchBaseUrl(dispatchId), status, filter, page, fromHistory, allRecommendations);
        },

        getRecommendationsForProject: function (dispatchProjectCode, status, filter, page, allRecommendations) {
            return getRecommendationsFromUrl(apiHelper.getDispatchProjectBaseUrl(dispatchProjectCode), status, filter, page, null, allRecommendations);
        },

        getRecommendationsForWorkOrder: function (workOrderId, status, filter, page, allRecommendations) {
            return getRecommendationsFromUrl(apiHelper.getWorkOrderBaseUrl(workOrderId), status, filter, page, null, allRecommendations);
        },

        getRecommendation: function (id) {
            return requests.getJson(apiHelper.getBaseUrl() + "/recommendations/" + id);
        }
    };

    return exports;
});
