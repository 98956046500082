import { autoinject, observable } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import { ValidationController, ValidationRules, validateTrigger } from "aurelia-validation";
import { Redirect, Router } from "aurelia-router";
import RouteRepository from "repositories/routeRepository";
import { PagingInfo } from "api/paging-info";
import { IRequestConfig } from "models/request-config";
import { default as _ } from "underscore";

// Model
import { DeliveryAddressModel } from "api/models/company/delivery-address-model";
import { DispatchTemplateModel } from "api/models/company/template/dispatch-template-model";
import { ServiceWorkOrderWriteModel } from "api/models/company/workorder/service-work-order-write-model";
import { ProjectBaseModel } from "api/models/company/project/project-base-model";
import { ClientLookupModel } from "api/models/company/client/client-lookup-model";
import { WorkOrderProgressStatusModel } from "api/models/company/workorder/work-order-progress-status-model";
import { WorkOrderLocationModel } from "api/models/company/work-order-location-model";
import { ClientModel } from "api/models/company/client/client-model";
import { WorkOrderTypeModel } from "api/models/company/workorder/work-order-type-model";

// Service and Proxy
import { TemplateService } from "services/template-service";
import { DeliveryAddressProxy } from "api/proxies/delivery-address-proxy";
import { ServiceWorkOrderProxy } from "api/proxies/service-work-order-proxy";
import { ServiceWorkOrderProjectProxy } from "api/proxies/service-work-order-project-proxy";
import { ClientProxy } from "api/proxies/client-proxy";
import { WorkOrderProgressStatusProxy } from "api/proxies/work-order-progress-status-proxy";
import { WorkOrderAddressProxy } from "api/proxies/work-order-address-proxy";
import { WorkOrderTypeProxy } from "api/proxies/work-order-type-proxy";
import { CustomerContactService } from "services/customer-contact-service";

// Helper
import { default as enumHelper, addressSources } from "helpers/enumHelper";
import { RouterHelper } from "helpers/router-helper";
import { StringHelper } from "helpers/string-helper";
import { default as phoneHelper } from "helpers/phoneHelper";
import { ValidationHelper } from "helpers/validation-helper";
import { default as settingHelper } from "helpers/settingHelper";
import { default as notificationHelper } from "helpers/notificationHelper";
import { TimeListHelper, TimeListItem } from "helpers/time-list-helper";
import { default as dateHelper } from "helpers/dateHelper";
import { ErrorManager } from "error-management/error-manager";
import { CloneHelper } from "helpers/cloneHelper";

// Enum
import { EnumFormatValueConverter } from "converters/enums/enum-format";
import { ServiceDispatchType } from "enums/service-dispatch-type";
import { ClientStatus } from "api/enums/client-status";
import { ServiceCallDispatchResponseModel } from "api/models/company/service-call/service-call-dispatch-response-model";
import { ValidationError } from "api/enums/validation-error";
import { ContactLookupModel } from "api/models/company/contact/contact-lookup-model";

@autoinject()
export class ServiceWorkOrderAdd {
    public i18n: I18N;

    @observable public displayAddressFields: boolean = false;
    @observable public dispatchTemplate!: DispatchTemplateModel;
    @observable public currentAddressSource: string = "";
    @observable public specificationItem: any;
    @observable public selectedCustomerId: string | null = "";
    @observable public selectedCustomerDescription: string | null = "";
    @observable public readonlyCustomer: boolean = false;
    @observable public readonlyProgressState: boolean = false;
    @observable public selectedWorkOrderTypeId: string | null = "";
    @observable public selectedWorkOrderTypeDescription: string | null = "";
    @observable public readonly: boolean = false;
    @observable public contactId: number | null = null;

    public addressSourceEnum: enumHelper.addressSources = enumHelper.addressSources();
    public addressSourceData: any[] = new Array();
    public serviceWorkOrderWriteModel!: ServiceWorkOrderWriteModel | null;
    public unModifiedServiceWorkOrderWriteModel!: ServiceWorkOrderWriteModel | null;
    public timeIncrement: number = 30;
    public timeOptions: TimeListItem[] = [];
    public startTime: number = 0;
    public duration: number = 1;
    public selectedProjectId: string | null = "";
    public currentAddress?: WorkOrderLocationModel;
    public isAlternativeAddress: boolean = false;
    public isCustomerAddress: boolean = false;
    public specificationId?: number;
    public isProjectEquipment: boolean = false;

    private readonly EMPTY_CHAR: string = "\0";
    private readonly DISPATCH_NOT_FOUND: number = 8;

    constructor(i18n: I18N,
                private readonly templateService: TemplateService,
                private readonly serviceWorkOrderProxy: ServiceWorkOrderProxy,
                private serviceWorkOrderProjectProxy: ServiceWorkOrderProjectProxy,
                private clientProxy: ClientProxy,
                private readonly workOrderProgressStatusProxy: WorkOrderProgressStatusProxy,
                private readonly workOrderAddressProxy: WorkOrderAddressProxy,
                private readonly deliveryAddressProxy: DeliveryAddressProxy,
                private readonly workOrderTypeProxy: WorkOrderTypeProxy,
                private readonly validationController: ValidationController,
                private readonly validationHelper: ValidationHelper,
                private readonly routeRepository: RouteRepository,
                private readonly routerHelper: RouterHelper,
                private readonly enumFormatValueConverter: EnumFormatValueConverter,
                private readonly errorManager: ErrorManager,
                private readonly customerContactService: CustomerContactService,
                private readonly router: Router) {
                this.i18n = i18n;
    }

    public get loadAlternativeSpecifications(): any {
        return {
            transport: (params: any, success: any, failure: any): any => {
                this.deliveryAddressProxy.GetDeliveryAddresses(params.data.filter, { page: params.data.page, pageSize: 20 }).then(
                    (result: any) => {
                        return success(result);
                    },
                    (fail: any) => {
                        return failure(fail);
                    }
                );
            },
            mapResults: (item: DeliveryAddressModel): any => {
                return { id: item.Id, text: item.Id + " - " + item.Name, data: this.formatDeliveryAddressModel(item) };
            },
        };
    }

    public get loadCustomerSpecifications(): any {
        return {
            transport: (params: any, success: any, failure: any): any => {
                this.deliveryAddressProxy.GetCustomerDeliveryAddresses(this.selectedCustomerId, params.data.filter, { page: params.data.page, pageSize: 20 }).then(
                    (result: any) => {
                        return success(result);
                    },
                    (fail: any) => {
                        return failure(fail);
                    }
                );

            },
            mapResults: (item: DeliveryAddressModel): any => {
                return { id: item.Id, text: item.Id + " - " + item.Name, data: this.formatDeliveryAddressModel(item) };
            },
        };
    }

    public async populateContacts(filter: string, pagingInfo: PagingInfo, requestConfig: IRequestConfig): Promise<ContactLookupModel[] | null> {
        if (this.selectedCustomerId) {
            return await this.customerContactService.getClientContacts(this.selectedCustomerId!, filter, pagingInfo, requestConfig);
        }
        return [];
    }

    public selectedContactChanged(event: CustomEvent<ContactLookupModel>): void {
        const selectedContact: ContactLookupModel | null = event ? event.detail : null;
        this.contactId = null;

        if (selectedContact !== null) {
            this.contactId = selectedContact.Id;
        }
    }

    public specificationItemChanged(): void {
        if (this.specificationItem === null) {
            this.clearCurrentAddress();
            return;
        }

        this.currentAddress = this.specificationItem.data.data;
    }

    public clearCurrentAddress(): void {
        this.currentAddress = undefined;
    }

    public async populateProjects(filter: string, pagingInfo: PagingInfo, requestConfig: IRequestConfig): Promise<ProjectBaseModel[] | null> {
        return await this.serviceWorkOrderProjectProxy.GetProjects(filter, pagingInfo, requestConfig);
    }

    public async selectedProjectChanged(event: CustomEvent<ProjectBaseModel | null>): Promise<void> {
        const selectedProject: ProjectBaseModel | null = event ? event.detail : null;

        this.readonly = false;
        this.readonlyCustomer = false;
        this.isProjectEquipment = false;

        if (!selectedProject) {
            return;
        }

        this.selectedProjectId = selectedProject.Id;
        this.isProjectEquipment = selectedProject.IsProjectEquipment;
       
        //get client from project
        this.contactId = null;
        this.selectedCustomerId = null;
        this.selectedCustomerDescription = null;
        const client: ClientModel | null = await this.clientProxy.GetClientFromProject(this.selectedProjectId);
        if (client) {

            if (client) {
                this.checkBlockedClient(client);
            }

            if (this.readonly) {
                return;
            }

            this.selectedCustomerId = client.Id;
            this.selectedCustomerDescription = client.Description;
            this.readonlyCustomer = true;
        }

        this.updateAddress();
    }

    public async populateCustomers(filter: string, pagingInfo: PagingInfo, requestConfig: IRequestConfig): Promise<ClientLookupModel[] | null> {
        return await this.clientProxy.GetClientsLookup(filter, pagingInfo, requestConfig);
    }

    public async selectedCustomerChanged(event: CustomEvent<ClientLookupModel | null>): Promise<void> {
        const selectedCustomer: ClientLookupModel | null = event ? event.detail : null;
        this.contactId = null;

        this.readonly = false;

        if (!selectedCustomer) {
            return;
        }

        const client: ClientModel | null = await this.clientProxy.GetClient(selectedCustomer.Id);
        if (client) {
            this.checkBlockedClient(client);
        }

        if (this.readonly) {
            return;
        }

        this.selectedCustomerId = selectedCustomer.Id;
        this.selectedCustomerDescription = selectedCustomer.Description;
        this.updateAddress();
    }

    public async populateWorkOrderTypes(filter: string, pagingInfo: PagingInfo, requestConfig: IRequestConfig): Promise<WorkOrderTypeModel[] | null> {
        return await this.workOrderTypeProxy.GetWorkOrderTypes(filter, pagingInfo, requestConfig);
    }

    public selectedWorkOrderTypeChanged(event: CustomEvent<WorkOrderTypeModel | null>): void {
        const selectedType: WorkOrderTypeModel | null = event ? event.detail : null;

        if (!selectedType) {
            return;
        }

        this.selectedWorkOrderTypeId = selectedType.Id;
        this.selectedWorkOrderTypeDescription = selectedType.Description;
    }

    public async populateProgressStates(filter: string, pagingInfo: PagingInfo, requestConfig: IRequestConfig): Promise<WorkOrderProgressStatusModel[] | null> {
        return await this.workOrderProgressStatusProxy.GetWorkOrderProgressStatuses(filter, pagingInfo, requestConfig);
    }

    public openAddressMap(): void {
        window.open(this.routerHelper.mapAddress(this.getAddressString(this.currentAddress!)));
    }

    public getAddressString(currentAddress: WorkOrderLocationModel): string {
        let addressString: string = "";

        if (!currentAddress || !currentAddress.Address) {
            return "";
        }

        addressString += currentAddress.Address;
        if (currentAddress.City || currentAddress.Province || currentAddress.PostalCode) {
            addressString += ", ";
            addressString += currentAddress.City ? currentAddress.City : "";
            addressString += currentAddress.Province ? " (" + currentAddress.Province + ") " : " ";
            addressString += currentAddress.PostalCode ? currentAddress.PostalCode : "";
        }

        return addressString;
    }

    public currentAddressSourceChanged(newValue: string, oldValue: any): void {
        if (!this.serviceWorkOrderWriteModel) {
            return;
        }

        if (newValue === oldValue) {
            return;
        }
        
        switch (newValue) {
            case this.addressSourceEnum.CLIENT.value:
                this.specificationItem = null;
                this.isCustomerAddress = true;
                this.isAlternativeAddress = false;
                this.updateAddress();
                break;
            case this.addressSourceEnum.PROJECT.value:
                this.specificationItem = null;
                this.updateAddress();
                this.isCustomerAddress = false;
                this.isAlternativeAddress = false;
                break;
            case this.addressSourceEnum.ALTERNATIVE.value:
                this.specificationItem = null;
                this.loadAlternativeAddress();
                this.isCustomerAddress = false;
                this.isAlternativeAddress = true;
                break;
            default:
                break;
        }
    }

    public async updateAddress(): Promise<void> {
        if (this.selectedProjectId && this.currentAddressSource === this.addressSourceEnum.PROJECT.value) {
            const woAddress = await this.workOrderAddressProxy.GetWorkOrderProjectAddressByProjectId(this.selectedProjectId);
            if (woAddress) {
                this.currentAddress = this.formatWorkOrderLocationModel(woAddress);
            }
        }

        if (this.selectedCustomerId && this.currentAddressSource === this.addressSourceEnum.CLIENT.value) {
            const woAddress = await this.workOrderAddressProxy.GetWorkOrderCustomerAddressByCustomerCode(this.selectedCustomerId);
            if (woAddress) {
                this.currentAddress = this.formatWorkOrderLocationModel(woAddress);
                this.specificationItem = {
                    id: this.currentAddress.SpecificationId,
                    text: this.currentAddress.SpecificationId + " - " + this.currentAddress.Name,
                    data: { data: this.currentAddress }
                };
            }
        }
    }

    public  canActivate(): boolean | Redirect {
        if (!settingHelper.hasDispatchModel()) {
            notificationHelper.showWarning(this.i18n.tr("DispatchModelRequired"));
            return new Redirect("Settings");
        }

        return true;
    }

    public async activate(): Promise<void> {
        await this.loadData();
        this.initializeControls();
        this.initValidation();
        this.initTimeOptions();
    }

    public async canDeactivate(): Promise<any> {
        if (this.isDirty()) {
            this.routerHelper.hideLoading(true);
            const msgWarning = this.i18n.tr("msg_UnsavedChangedWillBeLostConfirmation");
            const confirm = await notificationHelper.showDialogYesNo(msgWarning);
            if (!confirm) {
                return new Redirect((this.router.history as any).previousLocation, { trigger: false, replace: false });
            }
        }
        return true;
    }

    public isDirty(): boolean {
        if (!this.serviceWorkOrderWriteModel || !this.unModifiedServiceWorkOrderWriteModel) { return false; }

        const stringifyUnmodified = JSON.stringify(this.unModifiedServiceWorkOrderWriteModel).replace(/[^0-9A-Z]+/gi, "");
        this.setValuesForSave();
        const stringifyCurrent = JSON.stringify(this.serviceWorkOrderWriteModel).replace(/[^0-9A-Z]+/gi, "");

        return stringifyUnmodified !== stringifyCurrent;
    }
    
    public async loadData(): Promise<void> {
        const self: any = this;
        
        await this.templateService.getTemplateConfigs(settingHelper.getSelectedDispatchModel()).done((data: DispatchTemplateModel) => {
            self.dispatchTemplate = data;
        });

        const progressStatusList = await this.workOrderProgressStatusProxy.GetWorkOrderProgressStatuses("", undefined, undefined);

        this.readonlyProgressState = progressStatusList ? progressStatusList.length === 0 : true;

        this.serviceWorkOrderWriteModel = await this.serviceWorkOrderProxy.InitNewServiceWorkOrder();
        this.unModifiedServiceWorkOrderWriteModel = CloneHelper.deepClone(this.serviceWorkOrderWriteModel);

        this.startTime = dateHelper.toHourDecimal(new Date(Date.now()), false);
    }

    public setValuesForSave(): void {
        if (!this.serviceWorkOrderWriteModel) {
            return;
        }

        this.serviceWorkOrderWriteModel.StartDateTime = dateHelper.addHour(this.serviceWorkOrderWriteModel.Date, this.startTime);
        this.serviceWorkOrderWriteModel.EndDateTime = dateHelper.addHour(new Date(this.serviceWorkOrderWriteModel.StartDateTime), this.duration);
        this.serviceWorkOrderWriteModel.ProjectCode = this.selectedProjectId;
        this.serviceWorkOrderWriteModel.CustomerCode = this.selectedCustomerId;
        this.serviceWorkOrderWriteModel.location = this.getAddressDto();
        this.serviceWorkOrderWriteModel.WorkOrderTypeId = this.selectedWorkOrderTypeId;
        this.serviceWorkOrderWriteModel.ContactId = this.contactId !== null ? this.contactId : 0;
    }

    public async save(): Promise<void> {
        if (!this.serviceWorkOrderWriteModel) {
            return;
        }

        if (!await this.validateAndNotify()) {
            return;
        }

        if (this.isAlternativeAddress) {
            if (!this.alternativeAddressValidation()) {
                notificationHelper.showWarning(this.i18n.tr("msg_OneFieldRequired"));
                return;
            }
        }

        this.setValuesForSave();

        try {
            const response: ServiceCallDispatchResponseModel | null = await this.serviceWorkOrderProxy.CreateServiceWorkOrder(this.serviceWorkOrderWriteModel, this.dispatchTemplate.Code);
            if (response !== null) {

                this.unModifiedServiceWorkOrderWriteModel = null;

                const routeParameters: any = this.routerHelper.buildMixedRouteParameters(
                    {
                        serviceType: ServiceDispatchType.Workorder,
                        dispatchId: response.DispatchId
                    }, { readonly: false });

                const navigationParameters: {} = { replace: true, trigger: true };

                this.routerHelper.navigateToRoute(
                    this.routeRepository.routes.Service_Detail.name,
                    routeParameters,
                    navigationParameters
                );
            }
        } catch (error) {
            const validationException = this.errorManager.getApiValidationExceptionFromError(error);
            if (validationException && validationException.ErrorId === ValidationError.DispatchModelNotFound) {
                this.unModifiedServiceWorkOrderWriteModel = null;
                this.routerHelper.navigateToRoute(this.routeRepository.routes.Settings.name);
                notificationHelper.showError(this.i18n.tr(`ApiError.${ValidationError.DispatchModelNotFound}`) + " " + this.dispatchTemplate.Code, undefined, { timeOut: 0, extendedTimeOut: 0 });
            } else {
                throw error;
            }
        }
    }

    protected initValidation(): void {
        this.validationController.validateTrigger = validateTrigger.manual;

        ValidationRules
            .ensure((x: ServiceWorkOrderWriteModel) => x.Date).required().withMessageKey("err_DateRequired")
            .ensure((x: ServiceWorkOrderWriteModel) => x.Description).required().withMessageKey("err_DescriptionRequired")
            .ensure((x: ServiceWorkOrderWriteModel) => x.ProgressStatusId).required().when((x: ServiceWorkOrderWriteModel) => !this.readonlyProgressState).withMessageKey("err_ProgressStatusRequired")
            .on(this.serviceWorkOrderWriteModel);

        ValidationRules
            .ensure("selectedProjectId").required().withMessageKey("err_ProjectRequired")
            .ensure("selectedCustomerId").required().when(() => !this.isProjectEquipment).withMessageKey("err_CustomerRequired")
            .on(this);

    }

    protected async validateAndNotify(): Promise<boolean> {
        const isValid = await this.validationHelper.validateControllerAndNotifyUserIfNecessary(this.validationController);

        return isValid;
    }

    private loadAlternativeAddress(): any {
        this.clearCurrentAddress();
    }

    private initializeControls(): void {
        this.addressSourceData = _.map<any, any>(this.addressSourceEnum, (item: any) => {
            item.text = item.label;
            item.value = item.value;
            return item;
        });
    }

    private initTimeOptions(): void {
        this.timeOptions = TimeListHelper.loadTimeList(this.timeIncrement);
    }

    private alternativeAddressValidation(): boolean {
        if (!this.currentAddress) {
            return true;
        }

        if (this.currentAddress.SpecificationId !== "") {
            return true;
        }

        const haveMinimumAddressInfos: boolean = !!this.currentAddress.Name && !!this.currentAddress.Address && !!this.currentAddress.City;
        return haveMinimumAddressInfos;
    }

    private getAddressDto(): WorkOrderLocationModel {
        if ((this.isAlternativeAddress || this.isCustomerAddress) && !!this.specificationItem) {
            this.currentAddress!.SpecificationId = this.specificationItem.id;
        }

        if (!!this.currentAddress) {
            this.currentAddress.AddressType = this.currentAddressSource;
            this.currentAddress.SpecificationId = this.currentAddress.SpecificationId || "";

            this.currentAddress.Name = this.currentAddress.Name || "";
            this.currentAddress.Address = this.currentAddress.Address || "";
            this.currentAddress.City = this.currentAddress.City || "";
            this.currentAddress.Country = this.currentAddress.Country || "";
            this.currentAddress.Province = this.currentAddress.Province || "";

            this.currentAddress.PhoneNumber = StringHelper.cleanString(this.currentAddress.PhoneNumber || "", StringHelper.keepAlphaNumericOnly);
            this.currentAddress.PostalCode = StringHelper.cleanString(this.currentAddress.PostalCode || "", StringHelper.keepAlphaNumericOnly);

            this.currentAddress.ZipCode = this.currentAddress.ZipCode || "";
        }

        return this.currentAddress!;
    }

    private formatDeliveryAddressModel(data: DeliveryAddressModel): DeliveryAddressModel {
        data.PhoneNumber = phoneHelper.getDefaultFormat(data.PhoneNumber!);
        return data;
    }

    private formatWorkOrderLocationModel(data: WorkOrderLocationModel): WorkOrderLocationModel {
        data.AddressType = this.currentAddressSource;
        data.PhoneNumber = phoneHelper.getDefaultFormat(data.PhoneNumber!);
        return data;
    }

    private checkBlockedClient(client: ClientModel): void {
        if (client.Status === ClientStatus.Blocked) {
            this.readonly = true;
            const message = this.i18n.tr("msg_BlockedCustomer").replace("{0}", client.Id!).replace("{1}", client.Description!).replace("{2}", client.StatusReason!);
            notificationHelper.showWarning(message, "", { timeOut: 0 });
        } else if (client.Status === ClientStatus.Warning) {
            const warning = this.i18n.tr("msg_BlockedCustomerWarning").replace("{0}", client.Id!).replace("{1}", client.Description!).replace("{2}", client.StatusReason!);
            notificationHelper.showWarning(warning, "", { timeOut: 0 });
        }
    }
}
